@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css";
@import '../node_modules/rc-tooltip/assets/bootstrap_white.css';

.rc-tooltip {
  z-index: 5;
  opacity: 1;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
